'use client'

import { Link } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { Button } from '~/components/ui/Button.tsx'
import { Icon } from '~/components/ui/icon.tsx'
import { cn } from '~/utils/components.ts'

export default function CookieConsent({ variant = 'default', onAcceptCallback = () => {}, onDeclineCallback = () => {} }) {
	const [isOpen, setIsOpen] = useState(false)
	const [hide, setHide] = useState(false)

	useEffect(() => {
		// Check if the cookie consent has already been given
		const consentGiven = getCookie('cookieConsent')
		console.log('cookieConsent', consentGiven)
		if (!consentGiven) {
			setIsOpen(true)
		} else {
			setHide(true)
		}
	}, [])

	const accept = () => {
		setIsOpen(false)
		document.cookie = 'cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/'
		setTimeout(() => {
			setHide(true)
		}, 700)
		onAcceptCallback()
	}

	const decline = () => {
		setIsOpen(false)
		document.cookie = 'cookieConsent=false; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/'
		setTimeout(() => {
			setHide(true)
		}, 700)
		onDeclineCallback()
	}

	return variant == 'default' ? (
		<div className={cn('fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md', !isOpen ? 'translate-y-8 opacity-0 transition-[opacity,transform]' : 'translate-y-0 opacity-100 transition-[opacity,transform]', hide && 'hidden')}>
			<div className="dark:bg-card m-3 rounded-md border border-border bg-background shadow-lg">
				<div className="grid gap-2">
					<div className="flex h-14 items-center justify-between border-b border-border p-4">
						<h1 className="text-lg font-medium">Consentement cookie</h1>
						<Icon name="cookie" className="h-[1.2rem] w-[1.2rem]" />
					</div>
					<div className="p-4">
						<p className="text-start text-sm font-normal">
							Nous n'utilisons aucun traceur sur ce site web, aucune information n'est collectée ni partagée avec un tiers. En utilisant l'application, seuls des cookies nécessaires au fonctionnement du site sont déposés tels que les cookies de session (vous pouvez cependant vous y opposer en cliquant sur le bouton « Refuser » ci-dessous).
							<br />
							<br />
							<span className="text-xs">
								En cliquant sur "<span className="font-medium opacity-80">Accepter</span>", vous acceptez l'utilisation des cookies ainsi que notre politique de confidentialité.
							</span>
							<br />
							<Link to="/privacyPolicy" className="text-xs underline">
								En savoir plus sur la politique de confidentialité
							</Link>
						</p>
					</div>
					<div className="flex gap-2 border-t border-border p-4 py-5 dark:bg-background/20">
						<Button onClick={accept} className="w-full">
							Accepter
						</Button>
						<Button onClick={decline} className="w-full" variant="secondary">
							Refuser
						</Button>
					</div>
				</div>
			</div>
		</div>
	) : (
		variant == 'small' && (
			<div className={cn('fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md', !isOpen ? 'translate-y-8 opacity-0 transition-[opacity,transform]' : 'translate-y-0 opacity-100 transition-[opacity,transform]', hide && 'hidden')}>
				<div className="dark:bg-card m-3 rounded-lg border border-border bg-background">
					<div className="flex items-center justify-between p-3">
						<h1 className="text-lg font-medium">We use cookies</h1>
						<Icon name="cookie" className="h-[1.2rem] w-[1.2rem]" />
					</div>
					<div className="-mt-2 p-3">
						<p className="text-muted-foreground text-left text-sm">We use cookies to ensure you get the best experience on our website. For more information on how we use cookies, please see our cookie policy.</p>
					</div>
					<div className="mt-2 flex items-center gap-2 border-t p-3">
						<Button onClick={accept} className="h-9 w-full rounded-full">
							accept
						</Button>
						<Button onClick={decline} className="h-9 w-full rounded-full" variant="outline">
							decline
						</Button>
					</div>
				</div>
			</div>
		)
	)
}

// Function to get the value of a specific cookie
function getCookie(name: string) {
	const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
	return match ? match[2] : null
}
